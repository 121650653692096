import React from 'react'
import Header from '../components/Header'
import CustomNavbar from '../components/CustomNavbar'
import Footer from '../components/Footer'

const MainLayout = ({children}) => {
  return (
    <>
    <Header/>
    <CustomNavbar/>
    {children}
    <Footer/>
    </>
  )
}

export default MainLayout