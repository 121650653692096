import {
  BLOCK_ID_TX_7,
  TIME_AGO_TX_1,
  TIME_AGO_TX_4,
  TIME_AGO_TX_5,
  TIME_AGO_TX_7,
  TIMESTAMP_TX_1,
  TIMESTAMP_TX_4,
  TIMESTAMP_TX_5,
  TIMESTAMP_TX_7,
  TIME_AGO_TX_8,
  TIMESTAMP_TX_8,
  BLOCK_ID_TX_8,
  BLOCK_ID_TX_9,
  TIME_AGO_TX_9,
  TIMESTAMP_TX_9,
  BLOCK_ID_TX_10,
  TIME_AGO_TX_10,
  TIMESTAMP_TX_10,
} from "../constants/CommonValue";

export const blockData = [
  //BLOCK-DATA-1
  {
    id: "19636112",
    overview: {
      id: "19636112",
      blockHeight: "19636112",
      statusDone: true,
      timestamp: {
        timeAgo: TIME_AGO_TX_1,
        actualTimeStamp: TIMESTAMP_TX_1,
      },
      proposedOn: {
        slot: "8838302",
        epoch: "276196",
      },
      transcation: {
        count: "314 transactions",
        internalCount: "71 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "Titan Builder",
        time: "12",
      },
      blockReward:
        "0.010763966956061632 ETH (0 + 0.273388893744163972 - 0.26262492678810234)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "325,001",
      gasUsed: {
        value1: "25,098,183",
        value2: "83.66%",
        value3: "+67%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.00000001046390198",
        value2: "10.46390198 Gwei",
      },
      burntFees: "0.26262492678810234",
      etherPrice: "$3,239.12 / ETH",
      extraData:
        "Titan (titanbuilder.xyz) (Hex:0x546974616e2028746974616e6275696c6465722e78797a29)",
      moreDetails: {
        hash: "0x11cd0deaa49afa403237c513ea338ae21ba150b5ae1046007d9f4cd966a78093",
        parentHash:
          "0x363c43a39c906644e290c7f53d4adaf77566b31b6200af017add635a92e06657",
        stateRoot:
          "0x38e9ef6432d9d489e669beba75d05dec7701a1f2bd47fca219ba6c5408b907ee",
        withdrawalsRoot:
          "0x221053c53c9d5eedcf852a7ab63f388c5ee12db193e2a96bc26ca2348f1f58f5",
        nonce: "0x0000000000000000",
      },
    },
  },
  //BLOCK-DATA-2
  {
    id: "19638896",
    overview: {
      id: "19638896",
      blockHeight: "19638896",
      statusDone: true,
      timestamp: {
        timeAgo: "Apr-12-2024 10:20:59 AM +UTC",
        actualTimeStamp: "1712883647",
      },
      proposedOn: {
        slot: "8841103",
        epoch: "276284",
      },
      transcation: {
        count: "217 transactions",
        internalCount: "48 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "beaverbuild",
        time: "12",
      },
      blockReward:
        "0.025899384521608626 ETH (0 + 0.28607063710961721 - 0.260171252588008584)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "203,563",
      gasUsed: {
        value1: "16,384,154",
        value2: "54.61%",
        value3: "+9%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000015879443796",
        value2: "15.879443796 Gwei",
      },
      burntFees: "0.260171252588008584",
      extraData: "beaverbuild.org (Hex:0x6265617665726275696c642e6f7267)",
      etherPrice: "$3,239.12 / ETH",
      moreDetails: {
        hash: "0x2a2c2d1de41b5bc4518a8b4dd4b9e1888c5703c78a127b64646c500711d9af9c",
        parentHash:
          "0xc228163ced236614f17e2305f35a0b31bc4b29c69e396e3a2f3a058abc2a6207",
        stateRoot:
          "0xc9f952da99bb0abb84d93ae63c4c4e191723fa78c5a5566efed24386e4f9f670",
        withdrawalsRoot:
          "0x64037a710626cfed6566ab41d8f6dc804ae72395c6139fa543a7785e8bc489c4",
        nonce: "0x0000000000000000",
      },
    },
  },
  //BLOCK-DATA-3
  {
    id: "19659485",
    overview: {
      id: "19659485",
      blockHeight: "19659485",
      statusDone: true,
      timestamp: {
        timeAgo: "Apr-12-2024 10:20:59 AM +UTC",
        actualTimeStamp: "1712883647",
      },
      proposedOn: {
        slot: "8861900",
        epoch: "276934",
      },
      transcation: {
        count: "247 transactions",
        internalCount: "59 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "*rsync-builder.eth",
        time: "12",
      },
      blockReward:
        "0.025899384521608626 ETH (0 + 0.28607063710961721 - 0.260171252588008584)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "211,566",
      gasUsed: {
        value1: "23,629,956",
        value2: "78.77%",
        value3: "+58%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000009841723702",
        value2: "9.841723702 Gwei",
      },
      burntFees: "0.232559498042417112",
      extraData: "@rsyncbuilder (Hex:0x407273796e636275696c646572)",
      etherPrice: "$3,103.00 / ETH",
      moreDetails: {
        hash: "0x28d48d76ff5b24b2836dee494f4af2ac86ae7ec59b4d8660f3479045cfc77834",
        parentHash:
          "0x1c446302c6b249aa50910f69ed177b8b05938bf21e36fae5a5ac78d4a5e68f02",
        stateRoot:
          "0x8c952d173600d228f395f5b8f83c7e4b7e013f010e6d59c9420202bf0252566c",
        withdrawalsRoot:
          "0x9bca7c7c24cb8e9348608d19cf342eae60fb78b7578f821eaf2f2c5287e35529",
        nonce: "0x0000000000000000",
      },
    },
  },
  //BLOCK-DATA-4
  {
    id: "20792456",
    overview: {
      id: "20792456",
      blockHeight: "20792456",
      statusDone: true,
      timestamp: {
        timeAgo: TIME_AGO_TX_4,
        actualTimeStamp: TIMESTAMP_TX_4,
      },
      proposedOn: {
        slot: "10001599",
        epoch: "312549",
      },
      transcation: {
        count: "179 transactions",
        internalCount: "102 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "Lido: Execution Layer Rewards Vault",
        time: "12",
      },
      blockReward:
        "0.582382092774711895 ETH (0 + 1.09944586613783034 - 0.517063773363118445)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "60,805",
      gasUsed: {
        value1: "19,492,645",
        value2: "64.98%",
        value3: "+30%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000026526096041",
        value2: "26.526096041 Gwei",
      },
      burntFees: "0.517063773363118445",
      extraData:
        "Illuminate Dmocratize Dstribute (Hex:0x496c6c756d696e61746520446d6f63726174697a6520447374726962757465)",
      etherPrice: "$2,561.88 / ETH",
      moreDetails: {
        hash: "0x6151d4c0bb4377d14aad650ebf145cbb78def196ac3555af6aa66fc9452fea1d",
        parentHash:
          "0x2317797cfcbdfe1865dd2f32ee8ecd38483478c1c8d84a8228231974e78349fb",
        stateRoot:
          "0x67dbb3d0de451eeec3c44ae5a41e3df6b5d8548037935adcd039527dd4a4800a",
        withdrawalsRoot:
          "0x663c1998550e30a30fd7ace92516751626eb3da2ff37b7df0fd6a04ef7e5a750",
        nonce: "0x0000000000000000",
      },
    },
  },
  //BLOCK-DATA-5
  {
    id: "20384835",
    overview: {
      id: "20384835",
      blockHeight: "2038483",
      statusDone: true,
      timestamp: {
        timeAgo: TIME_AGO_TX_5,
        actualTimeStamp: TIMESTAMP_TX_5,
      },
      proposedOn: {
        slot: "9591817",
        epoch: "299744",
      },
      transcation: {
        count: "174 transactions",
        internalCount: "70 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "Lido: Execution Layer Rewards Vault",
        time: "12",
      },
      blockReward:
        "0.119615681119136607 ETH (0 + 0.202013407242126751 - 0.082397726122990144)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "65,337",
      gasUsed: {
        value1: "15,110,104",
        value2: "50.37%",
        value3: "+1%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000005453154136",
        value2: "5.453154136 Gwei",
      },
      burntFees: "0.082397726122990144",
      extraData:
        "Titan (titanbuilder.xyz) (Hex:0x546974616e2028746974616e6275696c6465722e78797a29)",
      etherPrice: "$3,174.48 / ETH",
      moreDetails: {
        hash: "0x65cf85fc3b07615515e303f61551fcd6e86c3afc544d1f92a9854b1492961466",
        parentHash:
          "0xcf2da97d8fed29bf20c1b86091737cf090fc56a5e4b34ce22df606d45ce2aa1e",
        stateRoot:
          "0xc0db8899f171275a7683e6a4d50b3b194025bf4f68f2c4dbe00f1446033c46db",
        withdrawalsRoot:
          "0x3041d0253b78062eabfaa0ce448975b9bdf394e5abe3388cfa53d6d2cd4ae1eb",
        nonce: "0x0000000000000000",
      },
    },
  },
  //BLOCK-DATA-7
  {
    id: BLOCK_ID_TX_7,
    overview: {
      id: BLOCK_ID_TX_7,
      blockHeight: BLOCK_ID_TX_7,
      statusDone: true,
      timestamp: {
        timeAgo: TIME_AGO_TX_7,
        actualTimeStamp: TIMESTAMP_TX_7,
      },
      proposedOn: {
        slot: "10001599",
        epoch: "312549",
      },
      transcation: {
        count: "179 transactions",
        internalCount: "102 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "Lido: Execution Layer Rewards Vault",
        time: "12",
      },
      blockReward:
        "0.582382092774711895 ETH (0 + 1.09944586613783034 - 0.517063773363118445)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "60,805",
      gasUsed: {
        value1: "19,492,645",
        value2: "64.98%",
        value3: "+30%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000026526096041",
        value2: "26.526096041 Gwei",
      },
      burntFees: "0.517063773363118445",
      extraData:
        "Illuminate Dmocratize Dstribute (Hex:0x496c6c756d696e61746520446d6f63726174697a6520447374726962757465)",
      etherPrice: "$2,561.88 / ETH",
      moreDetails: {
        hash: "0x6151d4c0bb4377d14aad650ebf145cbb78def196ac3555af6aa66fc9452fea1d",
        parentHash:
          "0x2317797cfcbdfe1865dd2f32ee8ecd38483478c1c8d84a8228231974e78349fb",
        stateRoot:
          "0x67dbb3d0de451eeec3c44ae5a41e3df6b5d8548037935adcd039527dd4a4800a",
        withdrawalsRoot:
          "0x663c1998550e30a30fd7ace92516751626eb3da2ff37b7df0fd6a04ef7e5a750",
        nonce: "0x0000000000000000",
      },
    },
  },
  //BLOCK-DATA-8
  {
    id: BLOCK_ID_TX_8,
    overview: {
      id: BLOCK_ID_TX_8,
      blockHeight: BLOCK_ID_TX_8,
      statusDone: true,
      timestamp: {
        timeAgo: TIME_AGO_TX_8,
        actualTimeStamp: TIMESTAMP_TX_8,
      },
      proposedOn: {
        slot: "10001599",
        epoch: "312549",
      },
      transcation: {
        count: "179 transactions",
        internalCount: "102 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "Lido: Execution Layer Rewards Vault",
        time: "12",
      },
      blockReward:
        "0.582382092774711895 ETH (0 + 1.09944586613783034 - 0.517063773363118445)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "60,805",
      gasUsed: {
        value1: "19,492,645",
        value2: "64.98%",
        value3: "+30%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000026526096041",
        value2: "26.526096041 Gwei",
      },
      burntFees: "0.517063773363118445",
      extraData:
        "Illuminate Dmocratize Dstribute (Hex:0x496c6c756d696e61746520446d6f63726174697a6520447374726962757465)",
      etherPrice: "$2,561.88 / ETH",
      moreDetails: {
        hash: "0x6151d4c0bb4377d14aad650ebf145cbb78def196ac3555af6aa66fc9452fea1d",
        parentHash:
          "0x2317797cfcbdfe1865dd2f32ee8ecd38483478c1c8d84a8228231974e78349fb",
        stateRoot:
          "0x67dbb3d0de451eeec3c44ae5a41e3df6b5d8548037935adcd039527dd4a4800a",
        withdrawalsRoot:
          "0x663c1998550e30a30fd7ace92516751626eb3da2ff37b7df0fd6a04ef7e5a750",
        nonce: "0x0000000000000000",
      },
    },
  },
  //BLOCK-DATA-9
  {
    id: BLOCK_ID_TX_9,
    overview: {
      id: BLOCK_ID_TX_9,
      blockHeight: BLOCK_ID_TX_9,
      statusDone: true,
      timestamp: {
        timeAgo: TIME_AGO_TX_9,
        actualTimeStamp: TIMESTAMP_TX_9,
      },
      proposedOn: {
        slot: "10001599",
        epoch: "312549",
      },
      transcation: {
        count: "179 transactions",
        internalCount: "102 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "Lido: Execution Layer Rewards Vault",
        time: "12",
      },
      blockReward:
        "0.582382092774711895 ETH (0 + 1.09944586613783034 - 0.517063773363118445)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "60,805",
      gasUsed: {
        value1: "19,492,645",
        value2: "64.98%",
        value3: "+30%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000026526096041",
        value2: "26.526096041 Gwei",
      },
      burntFees: "0.517063773363118445",
      extraData:
        "Illuminate Dmocratize Dstribute (Hex:0x496c6c756d696e61746520446d6f63726174697a6520447374726962757465)",
      etherPrice: "$2,561.88 / ETH",
      moreDetails: {
        hash: "0x6151d4c0bb4377d14aad650ebf145cbb78def196ac3555af6aa66fc9452fea1d",
        parentHash:
          "0x2317797cfcbdfe1865dd2f32ee8ecd38483478c1c8d84a8228231974e78349fb",
        stateRoot:
          "0x67dbb3d0de451eeec3c44ae5a41e3df6b5d8548037935adcd039527dd4a4800a",
        withdrawalsRoot:
          "0x663c1998550e30a30fd7ace92516751626eb3da2ff37b7df0fd6a04ef7e5a750",
        nonce: "0x0000000000000000",
      },
    },
  },
 
  //BLOCK-DATA-10
  {
    id: BLOCK_ID_TX_10,
    overview: {
      id: BLOCK_ID_TX_10,
      blockHeight: BLOCK_ID_TX_10,
      statusDone: true,
      timestamp: {
        timeAgo: TIME_AGO_TX_10,
        actualTimeStamp: TIMESTAMP_TX_10,
      },
      proposedOn: {
        slot: "10001599",
        epoch: "312549",
      },
      transcation: {
        count: "179 transactions",
        internalCount: "102 contract internal transactions",
      },
      Withdrawals: "16 withdrawals",
      feeRecipient: {
        name: "Lido: Execution Layer Rewards Vault",
        time: "12",
      },
      blockReward:
        "0.582382092774711895 ETH (0 + 1.09944586613783034 - 0.517063773363118445)",
      totalDifficulty: "58,750,003,716,598,352,816,469",
      size: "60,805",
      gasUsed: {
        value1: "19,492,645",
        value2: "64.98%",
        value3: "+30%",
      },
      gasLimit: "30,000,000",
      baseFee: {
        value1: "0.000000026526096041",
        value2: "26.526096041 Gwei",
      },
      burntFees: "0.517063773363118445",
      extraData:
        "Illuminate Dmocratize Dstribute (Hex:0x496c6c756d696e61746520446d6f63726174697a6520447374726962757465)",
      etherPrice: "$2,561.88 / ETH",
      moreDetails: {
        hash: "0x6151d4c0bb4377d14aad650ebf145cbb78def196ac3555af6aa66fc9452fea1d",
        parentHash:
          "0x2317797cfcbdfe1865dd2f32ee8ecd38483478c1c8d84a8228231974e78349fb",
        stateRoot:
          "0x67dbb3d0de451eeec3c44ae5a41e3df6b5d8548037935adcd039527dd4a4800a",
        withdrawalsRoot:
          "0x663c1998550e30a30fd7ace92516751626eb3da2ff37b7df0fd6a04ef7e5a750",
        nonce: "0x0000000000000000",
      },
    },
  },
];
