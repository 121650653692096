import React from "react";
import RedirectionText from "./RedirectionText";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommonBodyFooter = ({ footerDesc, linkTitle, linkUrl }) => {
  return (
    <div className="common-body-footer">
      <FontAwesomeIcon
        icon={faLightbulb}
        width={12}
        height={12}
        color="#6c757d"
      />{" "}
      {footerDesc}{" "}
      <RedirectionText label={linkTitle} outerNavigateLink={linkUrl} />.
    </div>
  );
};

export default CommonBodyFooter;
