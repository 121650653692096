import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CommonHorizontalDivider from "./CommonHorizontalDivider";

const CommonDropdown = ({
  dropdownOpen,
  toggle,
  dropdownTitle,
  menuItem = [],
  className,
}) => {
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      direction="down"
      className={`custom-dropdown-menu ${className}`}
    >
      <DropdownToggle className="custom-dropdown-menu-button d-flex align-items-center justify-content-center gap-1 py-0">
        {dropdownTitle}
        <div className="dropdown-icon">
          <FontAwesomeIcon
            icon={faChevronDown}
            width={11}
            height={6}
            color="#212529"
          />
        </div>
      </DropdownToggle>
      <DropdownMenu className="main-menu-body">
        {menuItem &&
          menuItem.length > 0 &&
          menuItem.map((item) => (
            <>
              {!item.isDivider ? (
                <DropdownItem
                  className="menu-item d-flex align-items-center gap-1"
                  key={item.value}
                >
                  {item?.logo && (
                    <img src={item?.logo} alt="" width={16} height={16} />
                  )}
                  {item?.label}
                </DropdownItem>
              ) : (
                <CommonHorizontalDivider className="m-2 opacity-100" />
              )}
            </>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CommonDropdown;
