import React, { useEffect, useState } from "react";
import CommonBodyFooter from "../../components/CommonBodyFooter";
import CommonNavigateBody from "../../components/CommonNavigateBody";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { COMMON_ADVERTISE_DATA } from "../../constants/AdvertiseData";
import CommonAdvertisementDropdown from "../../components/CommonAdvertisementDropdown";
import "./Address.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faMessage, faStar } from "@fortawesome/free-regular-svg-icons";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import HeaderLogo from "../../assests/images/addressHeaderLogo.png";
import AddressBelowHeaderChip from "./AddressBelowHeaderChip";
import AddressCardInfo from "./AddressCardInfo";
import TransactionMenu from "../transaction/TransactionMenu";
import AddressTable from "./AddressTable";
import CommonNoDataForCard from "../../components/CommonNoDataForCard";
import { useNavigate, useParams } from "react-router-dom";
import { addressData } from "../../data/AddressData";
const TAB_LIST = [
  { id: 1, name: "Transactions" },
  { id: 2, name: "Internal Transactions" },
  { id: 3, name: "Token Transfers (ERC-20)" },
  { id: 4, name: "NFT Transfers" },
  { id: 5, name: "Analytics" },
  { id: 6, name: "Multichain Portfolio" },
  { id: 7, name: "Cards" },
];

const ADVERTISE_DATA = [...COMMON_ADVERTISE_DATA];
const Address = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [addressDetails, setAddressDetails] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [isAddressFrom, setIsAddressFrom] = useState(false);

  const toggleTab = (id) => setActiveTab(id);
  useEffect(() => {
    if (id) {
      const data = addressData.find((item) => item?.id === id);
      if (data && data.id) {
        setAddressDetails({ ...data });
        setIsAddressFrom(data?.isFrom);
      } else {
        navigate("/error");
      }
    }
  }, [id]);
  return (
    <section className="address-body">
      <Container fluid="xxl">
        <div className="address-header">
          <div className="address-header-body">
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
              <div className="d-flex align-items-center gap-2 flex-wrap">
                <img src={HeaderLogo} alt="" width={24} height={24} />
                <h1 className="m-0">Address</h1>
                <p className="mb-0 text-break">{addressDetails?.id} </p>
                <div className="d-flex align-items-center gap-3 ms-1">
                  <FontAwesomeIcon
                    icon={faCopy}
                    width={12}
                    height={15}
                    color="#adb5bd"
                    onClick={() => {
                      navigator.clipboard.writeText(addressDetails?.id);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <FontAwesomeIcon
                    icon={faQrcode}
                    width={12}
                    height={15}
                    color="#adb5bd"
                  />
                  {isAddressFrom && (
                    <FontAwesomeIcon
                      icon={faMessage}
                      width={12}
                      height={15}
                      color="#adb5bd"
                    />
                  )}
                </div>
              </div>
              <div className="advertise-body d-flex align-items-center justify-content-center gap-2">
                <CommonAdvertisementDropdown
                  label="Buy"
                  list={[...ADVERTISE_DATA]}
                />
                <CommonAdvertisementDropdown
                  label="Exchange"
                  list={[{ ...ADVERTISE_DATA[1] }, { ...ADVERTISE_DATA[0] }]}
                />
                <CommonAdvertisementDropdown
                  label="Play"
                  list={[...ADVERTISE_DATA]}
                />
                <CommonAdvertisementDropdown
                  label="Gaming"
                  list={[{ ...ADVERTISE_DATA[1] }, { ...ADVERTISE_DATA[0] }]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <AddressBelowHeaderChip isAddressFrom={isAddressFrom} />
          <div className="d-flex align-items-center gap-1">
            <div className="star-icon-body">
              <FontAwesomeIcon
                icon={faStar}
                width={15}
                height={12}
                color="#212529"
              />
            </div>
            <TransactionMenu />
          </div>
        </div>
        <AddressCardInfo
          isAddressFrom={isAddressFrom}
          addressDetails={addressDetails}
        />
        <div className="common-tab-content">
          <div className="d-flex align-items-center justify-content-between">
            <Nav pills>
              {TAB_LIST &&
                TAB_LIST.length > 0 &&
                TAB_LIST.map((item) => (
                  <NavItem key={item.id}>
                    <NavLink
                      className={`${item.id === activeTab ? "active" : ""}`}
                      onClick={() => toggleTab(item.id)}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
            </Nav>
          </div>

          <TabContent activeTab={activeTab} className="address-all-tab">
            {Array.from({ length: 7 }, (_, index) => (
              <TabPane tabId={index + 1}>
                {index === 0 ? (
                  <AddressTable
                    addressTableDetails={addressDetails?.transactionTableData}
                    isAddressFrom={isAddressFrom}
                  />
                ) : (
                  <CommonNoDataForCard />
                )}
              </TabPane>
            ))}
          </TabContent>
        </div>
        <CommonBodyFooter
          footerDesc="A wallet address is a publicly available address that allows its owner to receive funds from another party. To access the funds in an address, you must have its private key. Learn more about addresses in our"
          linkTitle="Knowledge Base"
          linkUrl="https://info.etherscan.com/understanding-an-ethereum-transaction/"
        />
      </Container>
    </section>
  );
};

export default Address;
