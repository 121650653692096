import React, { useEffect, useState } from "react";
import "./transaction.scss";
import CommonBodyFooter from "../../components/CommonBodyFooter";
import CommonNavigateBody from "../../components/CommonNavigateBody";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CommonAdvertisementDropdown from "../../components/CommonAdvertisementDropdown";
import TransactionMenu from "./TransactionMenu";
import TransactionOverviewTab from "./TransactionOverviewTab";
import LogsOverviewTab from "./LogsOverviewTab";
import TransactionStateTab from "./TransactionStateTab";
import { COMMON_ADVERTISE_DATA } from "../../constants/AdvertiseData";
import { useNavigate, useParams } from "react-router-dom";
import { transactionData } from "../../data/TransactionData";

const TAB_LIST = [
  { id: 1, name: "Overview" },
  // { id: 2, name: "Logs" },
  { id: 3, name: "State" },
];

const ADVERTISE_DATA = [...COMMON_ADVERTISE_DATA];

const Transaction = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [trasactionDetails, setTrasactionDetails] = useState({});
  useEffect(() => {
    if (id) {
      const data = transactionData.find((item) => item?.id === id);
      if (data && data.id) {
        setTrasactionDetails({ ...data });
      } else {
        navigate("/error");
      }
    }
  }, [id]);
  const toggleTab = (id) => setActiveTab(id);
  return (
    <section className="transaction-body">
      <Container fluid="xxl">
        <div className="transaction-header">
          <div className="transaction-header-body">
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
              <div className="d-flex align-items-center gap-1">
                <h1 className="m-0">Transaction Details</h1>
                <CommonNavigateBody />
              </div>
              <div className="advertise-body d-flex align-items-center justify-content-center gap-2">
                <CommonAdvertisementDropdown
                  label="Buy"
                  list={[...ADVERTISE_DATA]}
                />
                <CommonAdvertisementDropdown
                  label="Exchange"
                  list={[{ ...ADVERTISE_DATA[1] }, { ...ADVERTISE_DATA[0] }]}
                />
                <CommonAdvertisementDropdown
                  label="Play"
                  list={[...ADVERTISE_DATA]}
                />
                <CommonAdvertisementDropdown
                  label="Gaming"
                  list={[{ ...ADVERTISE_DATA[1] }, { ...ADVERTISE_DATA[0] }]}
                />
              </div>
            </div>
          </div>

          <div className="transaction-sub-text">
            <b>Featured: </b>
            Elevate your website with curated <b>name tags and labels</b> 🏷️ in
            the all-new <b>Metadata API.</b>
            <a
              href="https://eas.etherscan.com/www/d/cl.php?ebannerid=4002&amp;zoneid=2&amp;sig=ee03226ffd864978a67983f2fd9aa92ba5b753585f9a172ad9680f6ff0bef497&amp;oadest=https%3A%2F%2Fmetadata.etherscan.io%2F"
              target="_blank"
              title="Links to an External Advertiser site"
              className="cu-text-primary"
            >
              {" "}
              <b>Learn More.</b>
            </a>
          </div>
        </div>
        <div className="common-tab-content transaction-tabs ">
          <div className="d-flex align-items-center justify-content-between">
            <Nav pills>
              {TAB_LIST &&
                TAB_LIST.length > 0 &&
                TAB_LIST.map((item) => (
                  <NavItem key={item.id}>
                    <NavLink
                      className={`${item.id === activeTab ? "active" : ""}`}
                      onClick={() => toggleTab(item.id)}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
            </Nav>
            <TransactionMenu />
          </div>

          <TabContent activeTab={activeTab} className="transaction-all-tab">
            <TabPane tabId={1}>
              <TransactionOverviewTab
                overviewDetails={trasactionDetails?.overview}
              />
            </TabPane>
            {/* <TabPane tabId={2}>
              <LogsOverviewTab />
            </TabPane> */}
            <TabPane tabId={3}>
              <TransactionStateTab stateDetails={trasactionDetails?.state} />
            </TabPane>
          </TabContent>
        </div>
        <CommonBodyFooter
          footerDesc="A transaction is a cryptographically signed instruction that changes the blockchain state. Block explorers track the details of all transactions in the network. Learn more about transactions in ours"
          linkTitle="Knowledge Base"
          linkUrl="https://info.etherscan.com/understanding-an-ethereum-transaction/"
        />
      </Container>
    </section>
  );
};

export default Transaction;
