import React, { useEffect, useState } from "react";
import "./MainBlock.scss";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import OverviewTab from "./OverviewTab";
import ConsensusTab from "./ConsensusTab";
import MevInfoTab from "./MevInfoTab";
import BlobInfoTab from "./BlobInfoTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import CommonBodyFooter from "../../components/CommonBodyFooter";
import CommonNoDataForCard from "../../components/CommonNoDataForCard";
import { blockData } from "../../data/BlockData";
import { useNavigate, useParams } from "react-router-dom";

const TAB_LIST = [
  { id: 1, name: "Overview" },
  { id: 2, name: "Consensus Info" },
  { id: 3, name: "MEV Info" },
  { id: 4, name: "Blob Info" },
];
const Block = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(1);
  const [blockDetails, setBlockDetails] = useState({});
  useEffect(() => {
    if (id) {
      const data = blockData.find((item) => item?.id === id);
      if (data && data.id) {
        setBlockDetails({ ...data });
      } else {
        navigate("/error");
      }
    }
  }, [id]);
  const toggleTab = (id) => setActiveTab(id);
  return (
    <section className="block-body">
      <Container fluid="xxl">
        <div className="block-header">
          <div className="block-main-header">
            <div className="header-body">
              <h1 className="m-0">Block</h1>
              <p className="text-muted text-break mb-0">
                #{blockDetails?.id}
              </p>
            </div>
            <div className="block-sub-header-chip pt-1 d-flex gap-1">
              <div className="sub-chip">
                #MEV block{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  width={10}
                  height={11}
                  color="#6c757d"
                />
              </div>
              <div className="sub-chip">
                #EigenPhi{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  width={10}
                  height={11}
                  color="#6c757d"
                />
              </div>
            </div>
          </div>
          <div className="block-sub-text">
            <b>Featured: </b>
            Elevate your website with curated <b>name tags and labels</b> 🏷️ in
            the all-new <b>Metadata API.</b>
            <a
              href="https://eas.etherscan.com/www/d/cl.php?ebannerid=4002&amp;zoneid=2&amp;sig=ee03226ffd864978a67983f2fd9aa92ba5b753585f9a172ad9680f6ff0bef497&amp;oadest=https%3A%2F%2Fmetadata.etherscan.io%2F"
              target="_blank"
              title="Links to an External Advertiser site"
              className="cu-text-primary"
            >
              {" "}
              <b>Learn More.</b>
            </a>
          </div>
        </div>
        <div className="common-tab-content block-tabs">
          <Nav pills>
            {TAB_LIST &&
              TAB_LIST.length > 0 &&
              TAB_LIST.map((item) => (
                <NavItem key={item.id}>
                  <NavLink
                    className={`${item.id === activeTab ? "active" : ""}`}
                    onClick={() => toggleTab(item.id)}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <OverviewTab blockOverview={blockDetails?.overview} />
            </TabPane>
            <TabPane tabId={2}>
              {/* <ConsensusTab /> */}
              <CommonNoDataForCard />
            </TabPane>
            <TabPane tabId={3}>
              {/* <MevInfoTab /> */}
              <CommonNoDataForCard />
            </TabPane>
            <TabPane tabId={4}>
              {/* <BlobInfoTab /> */}
              <CommonNoDataForCard />
            </TabPane>
          </TabContent>
        </div>
        <CommonBodyFooter
          footerDesc="Blocks are batches of transactions linked via cryptographic hashes. Any tampering of a block would invalidate all following blocks as all subsequent hashes would change. Learn more about this page in our"
          linkTitle="Knowledge Base"
          linkUrl="https://info.etherscan.com/exploring-block-details-page/"
        />
      </Container>
    </section>
  );
};

export default Block;
