import React from "react";
import { Container, Input } from "reactstrap";
import "../assests/scss/Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <section id="main-header" className="main-header">
      <Container fluid="xxl">
        <div className="main-header-body">
          <div className="left-price-body">
            <p className="common-header-text price-body mb-0 d-none d-md-block">
              ETH Price: <span className="price">$2,764.60</span>{" "}
              <span className="rate">(+3.06%)</span>
            </p>
            <p className="common-header-text gas-body mb-0 d-none d-lg-block">
              {/* fuel icon here */}
              <span>Gas: </span>
              <span className="measure">0.769 Gwei</span>
            </p>
          </div>
          <div className="search-body">
            <div className="search-icon">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>

            <Input placeholder="Search by Address / Txn Hash / Block / Token / Domain Name" />
            <div className="dash-icon">/</div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Header;
