import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CommonHorizontalDivider from "./CommonHorizontalDivider";
import SponseredCard from "./SponseredCard";

const CommonAdvertisementDropdown = ({ label, list }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="custom-adv-dropdown"
    >
      <DropdownToggle size="lg" className="cu-adv-dropdown-btn">
        {label}
        <div className="dropdown-icon">
          <FontAwesomeIcon
            icon={faChevronDown}
            width={11}
            height={11}
            color="#fff"
          />
        </div>
      </DropdownToggle>

      <DropdownMenu className="p-3">
        <p className="mb-0 sponsored-text">Sponsored</p>

        {list && list.length > 0 ? (
          <>
            {list.map((item, index) => (
              <>
                <DropdownItem className="p-0 pt-2" id={item.id}>
                  <SponseredCard
                    logo={item?.logo}
                    title={item?.title}
                    chipText={item?.chipText}
                    description={item?.description}
                    linkTitle={item?.linkTitle}
                    link={item?.link}
                  />
                </DropdownItem>
                {index < list.length - 1 && (
                  <CommonHorizontalDivider className="my-3" />
                )}
              </>
            ))}
          </>
        ) : (
          <></>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CommonAdvertisementDropdown;
