import React from "react";

const RedirectionText = ({
  label,
  outerNavigateLink = "",
  goToInnerNavigate = () => {},
  className,
  noLinkNavigation = false,
}) => {
  return outerNavigateLink && outerNavigateLink.length > 0 ? (
    <span className={className ? className : ""}>
      <a
        className="common-link-text text-truncate"
        href={outerNavigateLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className="text-break">{label}</span>
      </a>
    </span>
  ) : (
    <span
      className={`common-link-text ${className ? className : ""} ${
        noLinkNavigation ? "text-normal-body" : ""
      }`}
      onClick={() => !noLinkNavigation && goToInnerNavigate()}
    >
      {label}
    </span>
  );
};

export default RedirectionText;
