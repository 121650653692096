import Logo1 from "../assests/images/moon-logo.webp";
import Logo2 from "../assests/images/dog-logo.webp";

export const COMMON_ADVERTISE_DATA = [
  {
    id: 1,
    logo: Logo1,
    title: "MoonPay",
    chipText: "Buy Now!",
    linkTitle: "Buy, sell and swap Ethereum with MoonPay.",
    link: "https://moonpay-affiliate-program.sjv.io/GmG56B",
    description:
      "20M+ users trust MoonPay worldwide. Checkout with your preferred payment method.",
  },
  {
    id: 2,
    logo: Logo2,
    title: "MetaMask",
    chipText: "Try Now!",
    linkTitle: "Manage your web3 everything with MetaMask Portfolio. ",
    link: "https://goto.etherscan.com/rd/K534JZ53EY3BS4H6DIGP9SHB9",
    description:
      "Ready to onboard to Ethereum? With MetaMask Portfolio, you're in control. ",
  },
];
