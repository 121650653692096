import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DataTable from "react-data-table-component";
import { Card } from "reactstrap";
import RedirectionText from "../../components/RedirectionText";
import { getCenterDotFormattedId } from "../../helpers/HelperFunction";

const TransactionStateTab = ({ stateDetails }) => {
  const columns = [
    {
      name: "Address",
      selector: (row) => (
        <div className="d-flex align-items-center gap-1">
          <div className="link-warning-hover-body px-1">
            <RedirectionText
              label={getCenterDotFormattedId(row?.address?.title)}
              outerNavigateLink={row?.address?.link}
            />
          </div>
          {row?.address?.chipText && (
            <div className="common-state-chip">
              <p className="mb-0">{row?.address?.chipText}</p>
            </div>
          )}
        </div>
      ),
      maxWidth: "370px",
      minWidth: "370px",
    },
    {
      name: "",
      selector: (row) => row.platform,
      maxWidth: "140px",
      minWidth: "140px",
    },
    {
      name: "Before",
      selector: (row) => (
        <>
          <p className={row?.before?.nonce ? "mb-1" : "mb-0"}>
            {row?.before?.value}
          </p>
          {row?.before?.nonce && (
            <div className="common-state-chip">
              <p className="mb-0">Nonce : {row?.before?.nonce}</p>
            </div>
          )}
        </>
      ),
    },
    {
      name: "After",
      selector: (row) => (
        <>
          <p className={row?.after?.nonce ? "mb-1" : "mb-0"}>
            {row?.after?.value}
          </p>
          {row?.after?.nonce && (
            <div className="common-state-chip">
              <p className="mb-0">Nonce : {row?.after?.nonce}</p>
            </div>
          )}
        </>
      ),
    },
    {
      name: "State Difference",
      selector: (row) => (
        <div className="d-flex align-items-center gap-1">
          {row?.state?.isUp ? (
            <FontAwesomeIcon
              icon={faCaretUp}
              color="#6c757d"
              width={9}
              height={15}
            />
          ) : (
            <FontAwesomeIcon
              icon={faCaretDown}
              color="#6c757d"
              width={9}
              height={15}
            />
          )}
          <p className="mb-0">{row?.state?.value}</p>
        </div>
      ),
    },
  ];
  return (
    <Card className="common-tab-body mb-3 state-tab-body px-0">
      <div className="d-flex align-items-center state-desc-body">
        <div className="common-state-chip">
          <p className="mb-0">Advanced</p>
        </div>
        <p className="mb-0 text-break desc-text">
          A set of information that represents the current{" "}
          <span className="fw-bold">state</span> is updated when a transaction
          takes place on the network. The below is a summary of those changes :
        </p>
      </div>
      <DataTable
        columns={columns}
        data={stateDetails}
        responsive
        className="state-table-body"
      />
    </Card>
  );
};

export default TransactionStateTab;
