import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowRight,
  faPlus,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import RedirectionText from "../../components/RedirectionText";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import CommonDropdown from "../../components/CommonDropdown";
import BaseScanLogo from "../../assests/images/basescan.ico";
import BlockScan from "../../assests/images/blockscan.ico";
import BscScan from "../../assests/images/bscscan.ico";
import { getCalculatedValue, getTimeDiff } from "../../helpers/HelperFunction";
import { transactionTimeline } from "../../data/AddressData";
const allBlockScanItem = [
  { value: 1, label: "BaseScan ($29)", logo: BaseScanLogo },
  { value: 2, label: "BscScan ($27)", logo: BscScan },
  { value: 3, label: "", isDivider: true },
  { value: 4, label: "Blockscan (View All)", logo: BlockScan },
];
const AddressCardInfo = ({ isAddressFrom, addressDetails }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="address-card-info-body">
      <Row className="g-3">
        <Col md={12} lg={6} xl={4}>
          <Card className="custom-info-card h-100">
            <CardBody className="p-0">
              <h2 className="mb-0">Overview</h2>
              <div className="each-info">
                <p className="card-info-title">ETH Balance</p>
                <div className="d-flex align-items-center gap-1">
                  <FontAwesomeIcon
                    icon={faEthereum}
                    color="#6c757d"
                    width={9}
                    height={15}
                  />
                  <p className="card-info-value mb-0">
                    {addressDetails?.overview?.balance} ETH
                  </p>
                </div>
              </div>
              <div className="each-info">
                <p className="card-info-title">Eth Value</p>
                <span className="card-info-value mb-0">
                  ${getCalculatedValue(addressDetails?.overview?.balance)}{" "}
                </span>
                <span className="card-sub-info-value mb-0">
                  (@ ${addressDetails?.overview?.value?.secondary}/ETH)
                </span>
              </div>
              <div className="each-info">
                <p className="card-info-title">Token Holdings</p>
                <div className="d-flex align-items-center justify-content-between holding-body">
                  <div className="holding-value">
                    <span className="card-info-value mb-0">
                      $
                      {addressDetails?.isWitdrawUsd
                        ? addressDetails?.overview?.token?.value1
                        : getCalculatedValue(
                            addressDetails?.overview?.balance
                          )}{" "}
                    </span>
                    <span className="card-sub-seconday-info-value mb-0">
                      ({">"}
                      {addressDetails?.overview?.token?.value2})
                    </span>
                  </div>
                  <div className="wallet-body">
                    <FontAwesomeIcon
                      icon={faWallet}
                      width={14}
                      height={15}
                      color="#081d35"
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={12} lg={6} xl={4}>
          <Card className="custom-info-card h-100">
            <CardBody className="p-0">
              <h2 className="mb-0">More Info</h2>
              <div className="each-info">
                <p className="card-info-title">Private Name Tags</p>
                <div className="card-sub-info-value add-body mb-0">
                  <FontAwesomeIcon
                    icon={faPlus}
                    width={11}
                    height={12}
                    color="#212529"
                  />
                  <span className="ps-1">Add</span>
                </div>
              </div>
              <div className="each-info">
                <p className="card-info-title">Transactions Sent</p>
                <div className="d-flex gap-3">
                  <div className="sent-body">
                    <span className="title">Latest: </span>
                    <span className="value">
                      {getTimeDiff(
                        addressDetails?.moreInfo?.transactionTimeline?.latest
                      )}{" "}
                    </span>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      color="#081d35"
                      width={10}
                      height={15}
                    />
                  </div>
                  <div className="sent-body">
                    <span className="title">First: </span>
                    <span className="value">
                      {getTimeDiff(
                        addressDetails?.moreInfo?.transactionTimeline?.first
                      )}{" "}
                    </span>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      color="#081d35"
                      width={10}
                      height={15}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="each-info">
                <p className="card-info-title">Funded By</p>
                <span className="">N/A</span>
              </div> */}
              {/* <div className="each-info">
                <p className="card-info-title">
                  {isAddressFrom ? "Funded By" : "Contract Creator"}
                </p>
                <div className="funded-body">
                  <RedirectionText
                    label={
                      isAddressFrom ? "Stake.com 2" : "Bitfinex: Deployer 5"
                    }
                    outerNavigateLink={
                      isAddressFrom
                        ? "https://etherscan.io/address/0xdebfbe80c8aeba98a32968278463ccb639c6c4e3"
                        : "https://etherscan.io/address/0x36928500bc1dcd7af6a2b4008875cc336b927d57"
                    }
                  />{" "}
                  <FontAwesomeIcon
                    icon={faCopy}
                    width={12}
                    height={15}
                    color="#6c757d"
                  />{" "}
                  <span>at txn</span>{" "}
                  <RedirectionText
                    label={
                      isAddressFrom
                        ? "0xac2423c005ad23..."
                        : "0x2f1c5c2b44f77..."
                    }
                    outerNavigateLink={
                      isAddressFrom
                        ? "https://etherscan.io/tx/0xac2423c005ad2325c5942bbc1f73b7a3acfae64d40a693c982ada6c444998dfb"
                        : "https://etherscan.io/tx/0x2f1c5c2b44f771e942a8506148e256f94f1a464babc938ae0690c6e34cd79190"
                    }
                  />
                </div>
              </div>
              {!isAddressFrom && (
                <div className="each-info">
                  <p className="card-info-title">Token Tracker</p>
                  <div className="funded-body">
                    <RedirectionText
                      label={"Tether USD (USDT)"}
                      outerNavigateLink={
                        "https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7"
                      }
                    />{" "}
                    <span>(@$1.001)</span>
                  </div>
                </div>
              )} */}
            </CardBody>
          </Card>
        </Col>
        <Col md={12} lg={6} xl={4}>
          <Card className="custom-info-card h-100">
            <CardBody className="p-0 multi-chain-body">
              <h2 className="mb-0">Multichain Info</h2>
              <div className="each-info">
                <div className="multichain-price-body">
                  <span className="value">
                    {addressDetails?.multichainInfo?.value}
                  </span>
                  <span> (Multichain Portfolio)</span>
                </div>
              </div>
              <div className="each-info multichain-address-body d-flex align-items-center gap-1">
                <span>
                  {addressDetails?.multichainInfo?.addressFound} addresses found
                  via
                </span>
                <CommonDropdown
                  dropdownOpen={dropdownOpen}
                  toggle={toggle}
                  dropdownTitle="Blockscan"
                  menuItem={allBlockScanItem}
                  className="cu-dropdown-white"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddressCardInfo;
