import React from "react";
import CommonInfoHeaderLabel from "./CommonInfoHeaderLabel";
import { Col, Row } from "reactstrap";

const CommonInfoBody = ({
  children,
  label,
  customClassName,
  hideInfoIcon,
  customIcon,
  showFlexStart,
}) => {
  return (
    <Row
      className={`mb-3 ${
        showFlexStart ? "align-items-start" : "align-items-center"
      } ${customClassName}`}
    >
      <Col sm="auto" md={3}>
        <CommonInfoHeaderLabel
          label={label}
          hideInfoIcon={hideInfoIcon}
          customIcon={customIcon}
        />
      </Col>
      <Col sm="auto" md={9}>
        {children}
      </Col>
    </Row>
  );
};

export default CommonInfoBody;
