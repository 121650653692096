import React from "react";

const SponseredCard = ({
  logo,
  title,
  chipText,
  description,
  linkTitle,
  link,
}) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div
      className="sponsored-individual-card"
      onClick={() => openInNewTab(link)}
    >
      <div className="d-flex align-items-center gap-1 mb-2">
        <img src={logo} alt="" />
        <p className="mb-0 title">{title}</p>
      </div>
      <div className="link-body mb-2">
        <p className="sub-header mb-0">{linkTitle}</p>
        <p className="inner-chip mb-0">{chipText}</p>
      </div>
      <p className="desc-text mb-0">{description}</p>
    </div>
  );
};

export default SponseredCard;
