import {
  faArrowUpRightFromSquare,
  faCircleInfo,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const AddressBelowHeaderChip = ({ isAddressFrom }) => {
  return (
    <div className="address-chip-body d-flex align-items-center gap-1 flex-wrap">
      <div className="common-address-chip cu-secondary-chip d-flex gap-1 align-items-center">
        <FontAwesomeIcon icon={faTag} width={10} height={10} color="#6c757d" />
        <span className="cu-fw-500">
          {isAddressFrom ? "Stake.com" : "Tether: USDT Stablecoin"}
        </span>
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          width={10}
          height={10}
          color="#6c757d"
        />
      </div>
      {isAddressFrom ? (
        <div className="common-address-chip cu-normal-chip">
          <span className="cu-fw-500"># Gambling</span>
        </div>
      ) : (
        <>
          <div className="common-address-chip cu-success-chip">
            <span className="cu-fw-500">Source Code</span>
          </div>
          <div className="common-address-chip cu-normal-chip">
            <span># Tether</span>
          </div>
          <div className="common-address-chip cu-normal-chip d-flex align-items-center gap-1">
            <span># Blocked </span>
            <FontAwesomeIcon
              icon={faCircleInfo}
              width={10}
              height={10}
              color="#000"
            />
          </div>
          <div className="common-address-chip cu-normal-chip">
            <span># Stablecoin</span>
          </div>
          <div className="common-address-chip cu-normal-chip">
            <span>Token Contract</span>
          </div>
        </>
      )}
    </div>
  );
};

export default AddressBelowHeaderChip;
