import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const InfoIcon = ({ className }) => {
  return (
    <div className={`info-icon-body me-1 ${className}`}>
      <FontAwesomeIcon icon={faQuestion} width={5} height={5} color="#6c757d" />
    </div>
  );
};

export default InfoIcon;
