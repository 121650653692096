import { ETH_CURRENT_PRICE } from "../constants/CommonValue";

export const getCenterDotFormattedId = (id) => {
  if (id && id.length > 25) {
    let formattedText = "";
    formattedText += id.toString().substr(0, 10);
    formattedText += "...";
    formattedText += id.toString().slice(id.length - 9);
    return formattedText;
  }
  return id;
};

export const getTimeDiff = (timestamp = "1713166823") => {
  // get total seconds between the times
  const date_future = parseInt(new Date().getTime());
  const date_now = parseInt(timestamp) * 1000;
  // Calculate the difference in milliseconds
  const differenceInMilliseconds = date_future - date_now;
  //return second if millisecond less than 1000 * 60
  if (differenceInMilliseconds < 60000) {
    return `${Math.floor(differenceInMilliseconds / 1000)} secs ago`;
  }
  //return minute if millisecond less than 1000 * 60 * 60
  else if (differenceInMilliseconds < 3600000) {
    return `${Math.floor(differenceInMilliseconds / 60000)} minutes ago`;
  }
  //return hour if millisecond less than 1000 * 60 * 60 * 24
  else if (differenceInMilliseconds < 86400000) {
    return `${Math.floor(differenceInMilliseconds / 3600000)} hours ago`;
  }
  // Convert milliseconds to days
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const daysDifference = Math.floor(
    differenceInMilliseconds / millisecondsInADay
  );
  return `${daysDifference} days ago` || "";
};

export const getTotalValue = (list) => {
  let total = 0;
  list.map((item) => (total += parseFloat(item.replace(/,/g, ""))));
  return total;
};

export const getEtherBaseValue = (ethValue, total) => {
  return parseFloat(ethValue.replace(/,/g, "")) * parseInt(total);
};

export const getCalculatedValue = (val = "0.001290507761759") => {
  const totalValue =
    parseFloat(val.replace(/,/g, "")) *
    parseFloat(ETH_CURRENT_PRICE.replace(/,/g, ""));
  if (totalValue) {
    const valueInString = totalValue.toString();
    const dotIndex = valueInString.indexOf(".");

    const isDecimalValue = dotIndex >= 0;
    const intValue = valueInString.substring(
      0,
      isDecimalValue ? dotIndex : valueInString.length
    );
    // Reverse the string
    const reversed = intValue.split("").reverse().join("");

    // Add commas after every 3 characters
    const withCommas = reversed.replace(/(.{3})(?=.)/g, "$1,");
    // Reverse back the string to its original order
    const resultedValue = withCommas.split("").reverse().join("");
    return isDecimalValue
      ? resultedValue.concat(valueInString.substring(dotIndex))
      : resultedValue;
  }
  return (
    parseFloat(val.replace(/,/g, "")) *
      parseFloat(ETH_CURRENT_PRICE.replace(/,/g, "")) || "0"
  );
};
