import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
const CommonNavigateBody = () => {
  return (
    <>
      <span className="cu-navigate">
        <div className="common-navigate-body">
          <FontAwesomeIcon icon={faChevronLeft} width={6} height={6} />
        </div>
        <div className="common-navigate-body">
          <FontAwesomeIcon icon={faChevronRight} width={6} hightheight={6} />
        </div>
      </span>
    </>
  );
};

export default CommonNavigateBody;
