import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Transaction from "../pages/transaction/Transaction";
import Block from "../pages/block/Block";
import PublicRoute from "./PublicRoute";
import PageNotFound from "../pages/NotFound/PageNotFound";
import Address from "../pages/address/Address";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/tx/${12}`} replace />} />
      <Route
        path="tx/:id"
        element={
          <PublicRoute>
            <Transaction />
          </PublicRoute>
        }
      />
      <Route
        path="block/:id"
        element={
          <PublicRoute>
            <Block />
          </PublicRoute>
        }
      />
      <Route
        path="address/:id"
        element={
          <PublicRoute>
            <Address />
          </PublicRoute>
        }
      />
      <Route path="/error" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
