import React from "react";
import { ReactComponent as FooterLogo } from "../assests/images/footer-logo.svg";
import MapImage from "../assests/images/map.png";
import "../assests/scss/Footer.scss";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faHeart } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faMedium,
  faRedditAlien,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <section className="footer-body">
      <Container fluid="xxl">
        <div className="social-footer">
          <div className="social-footer-link">
            <div className="icon-body">
              <FontAwesomeIcon icon={faXTwitter} />
            </div>
            <div className="icon-body">
              <FontAwesomeIcon icon={faMedium} />
            </div>
            <div className="icon-body">
              <FontAwesomeIcon icon={faFacebook} />
            </div>
            <div className="icon-body">
              <FontAwesomeIcon icon={faRedditAlien} />
            </div>
          </div>
          <div className="back-to-top">
            <div className="combine-icon">
              <span className="dash-icon">__</span>
              <FontAwesomeIcon
                icon={faArrowUp}
                width={8}
                height={8}
                color="#212529"
              />
            </div>
            <span className="ps-1">Back to Top</span>
          </div>
        </div>
        <div className="main-footer-menu">
          <div className="row justify-content-md-between py-8 py-lg-10">
            <div className="col-lg-4 pe-xl-16 mb-4 mb-lg-0">
              <div className="d-flex align-items-center footer-title-body">
                <FooterLogo className="me-2" width="20" height="20" />
                <span className="fs-5 footer-title">Powered by Ethereum</span>
              </div>
              <p className="fs-sm footer-text">
                Etherscan is a Block Explorer and Analytics Platform for
                Ethereum, a decentralized smart contracts platform.
              </p>
              <div className="d-none d-lg-block mt-n4 mb-n6">
                <img
                  className="opacity-50"
                  width="280"
                  src={MapImage}
                  alt="Background Map Image"
                />
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg mb-10 mb-md-0 common-footer-menu">
              <h4 className="h6">Company</h4>

              <ul className="list-unstyled list-sm-space fs-sm mb-0">
                <li>
                  <a className="link-dark" href="https://etherscan.io/aboutus">
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://etherscan.io/brandassets"
                  >
                    Brand Assets
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://etherscan.io/contactus"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a className="link-dark" href="https://etherscan.io/careers">
                    <span className="me-1">Careers</span>{" "}
                    <span className="bg-primary text-white small fw-medium text-nowrap rounded-pill p-1 px-2">
                      We're Hiring!
                    </span>
                  </a>
                </li>
                <li>
                  <a className="link-dark" href="https://etherscan.io/terms">
                    Terms
                  </a>{" "}
                  &amp;{" "}
                  <a
                    className="link-dark"
                    href="https://etherscan.io/privacyPolicy"
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://etherscan.io/bugbounty"
                  >
                    Bug Bounty
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-4 col-lg mb-10 mb-md-0 common-footer-menu">
              <h4 className="h6">Community</h4>
              <ul className="list-unstyled list-sm-space fs-sm mb-0">
                <li>
                  <a
                    className="link-dark"
                    href="https://docs.etherscan.io/"
                    target="_blank"
                  >
                    API Documentation
                  </a>
                </li>
                <li>
                  <a className="link-dark" href="https://info.etherscan.com/">
                    Knowledge Base
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://etherscan.freshstatus.io/"
                    rel="nofollow noopener"
                    target="_blank"
                  >
                    Network Status
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://info.etherscan.com/newsletters/"
                  >
                    Newsletters
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-4 col-lg common-footer-menu mt-4 mt-md-0">
              <h4 className="h6">Products &amp; Services</h4>

              <ul className="list-unstyled list-sm-space fs-sm mb-0">
                <li>
                  <a
                    className="link-dark"
                    href="https://etherscan.io/contactusadvertise"
                  >
                    Advertise
                  </a>
                </li>
                <li>
                  <a className="link-dark" href="https://etherscan.io/eaas">
                    Explorer-as-a-Service (EaaS)
                  </a>
                </li>
                <li>
                  <a className="link-dark" href="https://etherscan.io/eaas">
                    API Plans
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://etherscan.io/priority-support"
                  >
                    Priority Support
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://blockscan.com"
                    target="_blank"
                  >
                    Blockscan{" "}
                    <i className="far fa-arrow-up-right-from-square text-muted ms-1"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="link-dark"
                    href="https://chat.blockscan.com/start"
                    target="_blank"
                  >
                    Blockscan Chat{" "}
                    <i className="far fa-arrow-up-right-from-square text-muted ms-1"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>Etherscan © {new Date().getFullYear()} (A1)</p>
          <p>
            <span>Donations:</span>
            <span className="cu-text-primary px-1">0x71c765...d8976f</span>
            <span>
              <FontAwesomeIcon icon={faHeart} style={{ color: "#ff0000" }} />
            </span>
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Footer;
