import React from "react";
import MainLayout from "../../layouts/MainLayout";
import "./NotFound.scss";
import { Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <section className="page-not-found">
        <Container fluid="xxl">
          <div class="text-muted main-body">
            <div class="text-body">
              <h1 class="text-muted fw-normal">
                <span class="fw-semi-bold me-2">Sorry!</span>We encountered an
                unexpected error.
              </h1>
              <p class="mb-0 text-break">
                An unexpected error occurred. <br />
                Please check back later
              </p>
            </div>
            <div class="py-5 ">
              <Button
                className="btn-primary back-button"
                onClick={() => navigate(-1)}
              >
                Back Home
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </MainLayout>
  );
};

export default PageNotFound;
