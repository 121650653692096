import {
  faChevronDown,
  faCircleCheck,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CommonHorizontalDivider from "../../components/CommonHorizontalDivider";

const TransactionMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      direction="down"
      className="transaction-menu "
    >
      <DropdownToggle className="transaction-menu-button d-flex align-items-center justify-content-center gap-1 py-0">
        <FontAwesomeIcon
          icon={faListCheck}
          width={12}
          height={6}
          color="#212529"
        />
        <div className="dropdown-icon">
          <FontAwesomeIcon
            icon={faChevronDown}
            width={11}
            height={6}
            color="#212529"
          />
        </div>
      </DropdownToggle>
      <DropdownMenu className="main-menu-body">
        <DropdownItem className="menu-item d-flex justify-content-center align-items-center gap-1">
          Validate Transaction
          <FontAwesomeIcon
            icon={faCircleCheck}
            width={12}
            height={12}
            color={"#00a186"}
          />
        </DropdownItem>
        <CommonHorizontalDivider className="m-2" />
        <DropdownItem className="menu-item">Geth Debug Trace</DropdownItem>
        <DropdownItem className="menu-item">Parity Trace</DropdownItem>
        <DropdownItem className="menu-item">Transaction Decoder</DropdownItem>
        <CommonHorizontalDivider className="m-2" />
        <DropdownItem className="menu-item">Get Raw Tx Hex</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default TransactionMenu;
