import React from "react";
import DataTable from "react-data-table-component";
import { Card } from "reactstrap";
import RedirectionText from "../../components/RedirectionText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye } from "@fortawesome/free-regular-svg-icons";
import InfoIcon from "../../components/InfoIcon";
import {
  getCenterDotFormattedId,
  getTimeDiff,
} from "../../helpers/HelperFunction";
import { useNavigate } from "react-router-dom";

const AddressTable = ({ addressTableDetails, isAddressFrom }) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: <InfoIcon className="address-info-icon" />,
      selector: (row) => (
        <div className="eye-body">
          <FontAwesomeIcon
            icon={faEye}
            width={11}
            height={10}
            color="#212529"
          />
        </div>
      ),
      center: true,
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      name: "Transaction Hash",
      selector: (row) => (
        <div className="d-flex align-items-center gap-1 cu-text-italic">
          <div className="pe-1">
            <RedirectionText
              label={row?.transactionHash?.title}
              goToInnerNavigate={() =>
                navigate(`/tx/${row?.transactionHash?.title}`)
              }
            />
          </div>
          <FontAwesomeIcon
            icon={faCopy}
            width={12}
            height={15}
            color="#6c757d"
            onClick={() => {
              navigator.clipboard.writeText(row?.transactionHash?.title);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
      minWidth: "160px",
      maxWidth: "160px",
    },
    {
      name: (
        <>
          <span className="me-1">Method</span>
          <InfoIcon className="address-info-icon" />
        </>
      ),
      selector: (row) => (
        <div className="method-transfer-body cu-text-italic">
          <span>{row?.method}</span>
        </div>
      ),
      minWidth: "130px",
      maxWidth: "130px",
    },
    {
      name: "Block",
      selector: (row) => <div className="cu-text-italic">{row?.block}</div>,
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      name: <div className="cu-text-primary">Age</div>,
      selector: (row) => (
        <div className="cu-text-italic">{getTimeDiff(row?.age)}</div>
      ),
      minWidth: "120px",
      maxWidth: "120px",
    },
    {
      name: "From",
      selector: (row) => (
        <div className="d-flex align-items-center gap-1">
          <span className="link-warning-hover-body px-1">
            <RedirectionText
              label={getCenterDotFormattedId(row?.from?.title)}
              noLinkNavigation={isAddressFrom}
              goToInnerNavigate={() => navigate(`/address/${row?.from?.title}`)}
            />
          </span>
          <FontAwesomeIcon
            icon={faCopy}
            width={12}
            height={15}
            color="#6c757d"
            onClick={() => {
              navigator.clipboard.writeText(row?.from?.title);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
      minWidth: "225px",
      maxWidth: "225px",
    },
    {
      name: "",
      selector: (row) => (
        <div
          className={`common-mode ${
            !isAddressFrom ? "mode-in" : "mode-out"
          } cu-text-italic`}
        >
          {!isAddressFrom ? "IN" : "OUT"}
        </div>
      ),
      minWidth: "70px",
      maxWidth: "70px",
    },
    {
      name: "To",
      selector: (row) => (
        <div className="d-flex align-items-center gap-1">
          <div className="link-warning-hover-body px-1">
            <RedirectionText
              label={getCenterDotFormattedId(row?.to?.title)}
              noLinkNavigation={!isAddressFrom}
              goToInnerNavigate={() => navigate(`/address/${row?.to?.title}`)}
            />
          </div>
          <FontAwesomeIcon
            icon={faCopy}
            width={12}
            height={15}
            color="#6c757d"
            onClick={() => {
              navigator.clipboard.writeText(row?.to?.title);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
      minWidth: "225px",
      maxWidth: "225px",
    },
    {
      name: <div className="cu-text-primary">Amount</div>,
      selector: (row) => (
        <div className="cu-text-italic">{row?.amount.slice(0, 10)} ETH</div>
      ),
      minWidth: "145px",
      maxWidth: "145px",
    },
    {
      name: <div className="cu-text-primary">Txn Fee</div>,
      selector: (row) => (
        <div className="row-tx-fee cu-text-italic">
          {row?.txnFee?.slice(0, 10)}
        </div>
      ),
    },
  ];
  return (
    <Card className="common-tab-body mb-3 px-0 address-table">
      <DataTable
        columns={columns}
        data={addressTableDetails}
        responsive
        className="state-table-body"
      />
    </Card>
  );
};

export default AddressTable;
