import React, { useState } from "react";
import { ReactComponent as MainLogo } from "../assests/images/main-logo.svg";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Container,
  NavLink,
  Dropdown,
} from "reactstrap";
import "../assests/scss/CustomNavbar.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";

const CustomNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(0);

  const toggle = () => setIsOpen(!isOpen);
  const onMouseEnterHandler = (num) => {
    setDropdownOpen(num);
  };
  const onMouseLeaveHandler = () => {
    setDropdownOpen(0);
  };

  const dropdownHandlerForToggleCollapse = (num) => {
    num === dropdownOpen ? setDropdownOpen(0) : setDropdownOpen(num);
  };
  return (
    <section className="main-navbar">
      <Container fluid="xxl">
        <div className="main-navbar-body">
          <Navbar expand="lg">
            <NavbarBrand href="/">
              <div className="logo-part">
                <MainLogo />
              </div>
            </NavbarBrand>{" "}
            <NavbarText className="d-block d-lg-none">
              <FontAwesomeIcon icon={faCircleUser} />
              <span className="ps-1 sign-in-link-color-cursor">Sign In</span>
            </NavbarText>
            <NavbarToggler
              onClick={toggle}
              className={`main-navbar-toggler `}
            />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <NavLink className="home-link">
                    <Link to="/">Home</Link>
                  </NavLink>
                </NavItem>

                <Dropdown
                  nav
                  inNavbar
                  className="custom-dropdown"
                  isOpen={dropdownOpen === 1}
                  toggle={() => dropdownHandlerForToggleCollapse(1)}
                >
                  <DropdownToggle
                    nav
                    className="cu-dropdown-toggle cu-text-primary"
                    onMouseEnter={() => onMouseEnterHandler(1)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    BlockChain
                    <div className="dropdown-icon">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    onMouseEnter={() => onMouseEnterHandler(1)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    <DropdownItem>Transactions</DropdownItem>
                    <DropdownItem>Pending Transactions</DropdownItem>
                    <DropdownItem>Contract Internal Transactions</DropdownItem>
                    <DropdownItem>Beacon Deposits</DropdownItem>
                    <DropdownItem>Beacon Withdrawals</DropdownItem>
                    <DropdownItem>View Blobs</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>View Blocks</DropdownItem>
                    <DropdownItem>Forked Blocks (Reorgs)</DropdownItem>
                    <DropdownItem>Uncles</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Top Accounts</DropdownItem>
                    <DropdownItem>Verified Contracts</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  nav
                  inNavbar
                  className="custom-dropdown"
                  isOpen={dropdownOpen === 2}
                  toggle={() => dropdownHandlerForToggleCollapse(2)}
                >
                  <DropdownToggle
                    nav
                    className="cu-dropdown-toggle"
                    onMouseEnter={() => onMouseEnterHandler(2)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    Tokens
                    <div className="dropdown-icon">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    onMouseEnter={() => onMouseEnterHandler(2)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    <DropdownItem>
                      Top Tokens{" "}
                      <span className="dropdown-menu-sub-text">(ERC-20)</span>
                    </DropdownItem>
                    <DropdownItem>
                      Token Transfers{" "}
                      <span className="dropdown-menu-sub-text">(ERC-20)</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  nav
                  inNavbar
                  className="custom-dropdown"
                  isOpen={dropdownOpen === 3}
                  toggle={() => dropdownHandlerForToggleCollapse(3)}
                >
                  <DropdownToggle
                    nav
                    className="cu-dropdown-toggle"
                    onMouseEnter={() => onMouseEnterHandler(3)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    NFTs
                    <div className="dropdown-icon">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    onMouseEnter={() => onMouseEnterHandler(3)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    <DropdownItem>Top NFTs</DropdownItem>
                    <DropdownItem>Top Mints</DropdownItem>
                    <DropdownItem>Latest Trades</DropdownItem>
                    <DropdownItem>Latest Transfers</DropdownItem>
                    <DropdownItem>Latest Mints</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  nav
                  inNavbar
                  className="custom-dropdown"
                  isOpen={dropdownOpen === 4}
                  toggle={() => dropdownHandlerForToggleCollapse(4)}
                >
                  <DropdownToggle
                    nav
                    className="cu-dropdown-toggle"
                    onMouseEnter={() => onMouseEnterHandler(4)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    Resources
                    <div className="dropdown-icon">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    onMouseEnter={() => onMouseEnterHandler(4)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    <DropdownItem>Charts And Stats</DropdownItem>
                    <DropdownItem>Top Statistics</DropdownItem>
                    <DropdownItem>Leaderboard</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Directory</DropdownItem>
                    <DropdownItem>Newsletter</DropdownItem>
                    <DropdownItem>Knowledge Base</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  nav
                  inNavbar
                  className="custom-dropdown"
                  isOpen={dropdownOpen === 5}
                  toggle={() => dropdownHandlerForToggleCollapse(5)}
                >
                  <DropdownToggle
                    nav
                    className="cu-dropdown-toggle"
                    onMouseEnter={() => onMouseEnterHandler(5)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    Developers
                    <div className="dropdown-icon">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    onMouseEnter={() => onMouseEnterHandler(5)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    <DropdownItem>API Plans</DropdownItem>
                    <DropdownItem>API Documentation</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Code Reader</DropdownItem>
                    <DropdownItem>Verify Contract</DropdownItem>
                    <DropdownItem>Similar Contract Search</DropdownItem>
                    <DropdownItem>Smart Contract Search</DropdownItem>
                    <DropdownItem>Contract Diff Checker</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Vyper Online Compiler</DropdownItem>
                    <DropdownItem>Bytecode to Opcode</DropdownItem>
                    <DropdownItem>Broadcast Transaction</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  nav
                  inNavbar
                  className="custom-dropdown"
                  isOpen={dropdownOpen === 6}
                  toggle={() => dropdownHandlerForToggleCollapse(6)}
                >
                  <DropdownToggle
                    nav
                    className="cu-dropdown-toggle"
                    onMouseEnter={() => onMouseEnterHandler(6)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    More
                    <div className="dropdown-icon">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    onMouseEnter={() => onMouseEnterHandler(6)}
                    onMouseLeave={() => onMouseLeaveHandler()}
                  >
                    <DropdownItem>Transactions</DropdownItem>
                    <DropdownItem>Input Data Decoder</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>View Blocks</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
            <span className="d-none d-lg-block">|</span>
            <NavbarText className="d-none d-lg-block">
              <FontAwesomeIcon icon={faCircleUser} />
              <span className="ps-1 sign-in-link-color-cursor">Sign In</span>
            </NavbarText>
          </Navbar>
        </div>
      </Container>
    </section>
  );
};

export default CustomNavbar;
