import React from "react";
import { Card } from "reactstrap";

const CommonNoDataForCard = () => {
  return (
    <Card className="common-tab-body mb-3 px-0 common-card-no-data-body">
      <span>No data available</span>
    </Card>
  );
};

export default CommonNoDataForCard;
