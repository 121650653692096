import React from "react";
import InfoIcon from "./InfoIcon";

const CommonInfoHeaderLabel = ({ label, hideInfoIcon, customIcon }) => {
  return (
    <div className="d-flex align-items-center cu-common-info-header">
      {customIcon ? customIcon : !hideInfoIcon && <InfoIcon />}
      <span className="label">{label}</span>
    </div>
  );
};
export default CommonInfoHeaderLabel;
